"use client";

import { useState, FC, useCallback } from "react";
import cx from "classnames";
import styles from "./footer-map.module.scss";
import { formatPhone, formatTimePeriod } from "../../../utils/stringFormatter";
import { getLanguage } from "../../../utils/localeUtils";
import Button from "../../common-components/button";
import DirectionIcon from "../../icons/direction-icon";
import { getGoogleMapsDirectionUrl } from "../../../utils/map";
import { useIsMobile } from "../../../utils/useIsMobile";
import { formatAddress } from "../../../utils/stringFormatter";
import { IPageBlock } from "../../../types/ui";
import BlockImage from "../../common-components/block-image";
import { sendAnalyticsEvent } from "../../../utils/analitics";
import { getTranslationModule } from "../../../utils/translations";
import { LeadSection } from "@/types/lead";

const FooterMap: FC<IPageBlock> = ({ siteData, pageContext, config, translations }) => {
  const isMobile = useIsMobile(undefined, pageContext.isSmartphone);
  const t = getTranslationModule(translations, "common");

  const defaultDealer = siteData?.dealerInfos[0];
  const departments = defaultDealer?.departments.filter(({ title, phone }) => {
    return Object.values(LeadSection).includes(title as LeadSection) && !!phone;
  });
  const [activeDepartment, setActiveDepartment] = useState(departments[0]);
  const dealerSchedule = activeDepartment.schedule || [];
  const mapImage = config.image;

  const contactUsText = t("contact_us");
  const contactUsPhones = siteData.customPhones
    ? siteData.customPhones.map((item) => ({
        name: item.title,
        value: formatPhone(item.phone),
      }))
    : departments.map((item) => ({
        name: item.title,
        value: formatPhone(item.phone),
      }));

  const scheduleText = t("working_hours");
  const language = getLanguage(pageContext.locale);
  const scheduleItems = dealerSchedule.map((item, index) => ({
    name: new Date(2022, 2, 20 + index + 1).toLocaleDateString(language, { weekday: "long" }),
    value: item.workingTime ? formatTimePeriod(item.workingTime!) : t("closed"),
  }));

  const sunday = scheduleItems.pop();
  scheduleItems.unshift(sunday!);

  const address = formatAddress(defaultDealer.address);

  const onPhoneClick = useCallback((phone: string) => {
    sendAnalyticsEvent("asc_click_to_call", { comm_phone_number: phone });
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.mainContent}>
        <div>
          <h4 title={contactUsText}>{contactUsText}</h4>
          <ul className={styles.phones}>
            {contactUsPhones.map((item, index) => (
              <li key={index}>
                <span>{item.name}</span>{" "}
                <a title={item.value} href={`tel:${item.value}`} onClick={() => onPhoneClick(item.value)}>
                  {item.value}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.mainContentHr} />
        </div>
        <div>
          <h4 title={scheduleText}>{scheduleText}</h4>
          <ul className={styles.departments}>
            {departments?.map((item, index) => (
              <li
                className={cx(styles.departmentsItem, {
                  [styles.departmentsItemActive]: item.title === activeDepartment?.title,
                })}
                onClick={() => {
                  let newActiveDepartment = departments?.find((department) => department.title === item.title);
                  if (newActiveDepartment) {
                    setActiveDepartment(newActiveDepartment);
                  }
                }}
                key={index}
              >
                {item?.title}
              </li>
            ))}
          </ul>
          <ul className={styles.schedule}>
            {scheduleItems.map((item, index) => (
              <li key={index}>
                <span>{item.name}</span> <span>{item.value}</span>
              </li>
            ))}
          </ul>
          <div className={styles.mainContentHr} />
        </div>
      </div>
      <div className={styles.map}>
        <BlockImage siteData={siteData} mobile={isMobile} image={mapImage} alt="map" lazyLoading={true} />
        <div className={styles.mapItems}>
          <p className={styles.address}>{address}</p>
          <span className={styles.pin}></span>
        </div>
        <Button
          name={"getDirectionsButton"}
          ariaLabel={t("get_direction")}
          type={"primary"}
          text={t("get_direction")}
          onClick={() => window.open(getGoogleMapsDirectionUrl(defaultDealer.location), "_blank")}
          className={styles.getDirections}
          icon={<DirectionIcon />}
        />
      </div>
    </div>
  );
};

export default FooterMap;
