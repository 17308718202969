"use client";
import { useState, FC, useCallback, useEffect, useMemo } from "react";
import cx from "classnames";
import { IPageBlock } from "../../../types/ui";
import styles from "./styles.module.scss";
import ContactForm from "../../common-components/contact-form";
import { addVehicleLead } from "../../../api/client-api";
import { DropDownOption } from "../../../types/dropdown";
import Button from "../../common-components/button";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import { createAnalyticsDataFromVehicle, sendAnalyticsEvent } from "../../../utils/analitics";
import { VehicleLeadData } from "../../../types/lead";
import { getTranslationModule } from "../../../utils/translations";
import { FilterValue } from "../../../types/filters";

export interface IVehicleLeadFormData {
  title?: string;
  text?: string;
  buttonTitle: string;
  filters: {
    filter: FilterValue[];
  };
}

const VehicleLeadForm: FC<IPageBlock> = ({ config, siteData, translations }) => {
  const t = getTranslationModule(translations, "common");
  const leadConfig: IVehicleLeadFormData = config;
  const filters = leadConfig?.filters?.filter;
  const defaultDealer = siteData.dealerInfos[0];
  const dealerId = defaultDealer?.id;
  const contactOptions: DropDownOption[] = siteData.popupConfig.contactOptions;
  const currentContactOption = contactOptions[0].value;
  const leadSource = "vehicleLeadForm";

  const [formEngagementSent, setFormEngagementSent] = useState(false);
  const vehicleEventData = createAnalyticsDataFromVehicle();
  const formEventData = useMemo(() => {
    return {
      comm_type: "form",
      form_name: leadSource,
      form_type: "consumer_contact",
      ...vehicleEventData,
    };
  }, [vehicleEventData]);

  const [formValidator, setFormValidator] = useState(
    new FormFieldValidator({
      firstName: "",
      lastName: "",
      contactType: currentContactOption,
      phone: "",
      email: "",
      zipCode: "",
    }),
  );

  const [disabled, setDisabled] = useState(false);

  const onSubmit = useCallback(() => {
    if (formValidator.validate()) {
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: t("submit"),
        element_type: "form",
        event_action_result: "success",
        element_title: "submit",
        link_url: "",
      });
      sendAnalyticsEvent("asc_form_engagement", { comm_status: "crm_update", ...formEventData });

      setDisabled(true);
      const leadData: VehicleLeadData = {
        ...formValidator.formData,
        source: leadSource,
        siteInstanceId: siteData.id,
        siteConfigId: siteData.siteConfigId,
        make: filters.find((filter) => filter.filter === "make")?.value,
        model: filters.find((filter) => filter.filter === "model")?.value,
        year: filters.find((filter) => filter.filter === "year")?.value,
        trim: filters.find((filter) => filter.filter === "trim")?.value,
        type: filters.find((filter) => filter.filter === "type")?.value,
      };

      addVehicleLead(dealerId, leadData).then(() => {
        sendAnalyticsEvent("asc_form_submission", {
          ...formEventData,
          department: "sales",
        });

        setTimeout(() => {
          setFormValidator(
            new FormFieldValidator({
              firstName: "",
              lastName: "",
              contactType: currentContactOption,
              phone: "",
              email: "",
              zipCode: "",
            }),
          );
          setDisabled(false);
        }, 7000);
      });
    }
  }, [filters, formValidator, t, formEventData, siteData.id, siteData.siteConfigId, dealerId, currentContactOption]);

  useEffect(() => {
    if (!formEngagementSent) {
      sendAnalyticsEvent("asc_form_engagement", { comm_status: "start", ...formEventData });
      setFormEngagementSent(true);
    }
  }, [formEngagementSent, formEventData]);

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        {leadConfig?.title && <h4 className={styles.title}>{leadConfig.title}</h4>}
        {leadConfig?.text && <p className={styles.text}>{leadConfig.text}</p>}
        <ContactForm
          formValidator={formValidator}
          disabled={disabled}
          contactOptions={contactOptions}
          formEventData={formEventData}
          zipCode
          translations={translations}
        />
        {!disabled && (
          <Button
            name={"contactMeButton"}
            type={"primary"}
            text={leadConfig.buttonTitle}
            className={styles.submitButton}
            onClick={onSubmit}
            ariaLabel={leadConfig.buttonTitle}
          />
        )}
        {disabled && <p className={cx(styles.text, styles.textForm)}>{t("thank_you_lead")}</p>}
      </div>
    </section>
  );
};

export default VehicleLeadForm;
