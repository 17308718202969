"use client";

import { FC, useEffect } from "react";
import { createAnalyticsDataFromVehicle, fillAscDataLayer, sendAnalyticsEvent } from "../../../utils/analitics";
import { ModelCount } from "../../../types/ModelInfo";
import { Vehicle } from "../../../types/vehicle";
import useAnalytics from "@/analytics/useAnalytics";

interface IPageAnalytics {
  locale: string;
  newModels: ModelCount[];
  createAscDataLayer?: boolean;
  vehicles?: Vehicle[];
  vehicle?: Vehicle;
  dealerName: string;
}

const PageAnalytics: FC<IPageAnalytics> = ({
  newModels,
  createAscDataLayer,
  vehicles,
  dealerName,
  locale,
  vehicle,
}) => {
  const { pageType } = useAnalytics();

  useEffect(() => {
    const dataLayerVehicles = vehicles || (vehicle ? [vehicle] : []);
    fillAscDataLayer(newModels, dataLayerVehicles, pageType, locale, dealerName, createAscDataLayer);
    if (!vehicle) {
      sendAnalyticsEvent("asc_pageview", { page_type: pageType, item_results: dataLayerVehicles.length });
    } else {
      const vehcileData = createAnalyticsDataFromVehicle(vehicle);
      sendAnalyticsEvent("asc_pageview", {
        page_type: pageType,
        item_results: dataLayerVehicles.length,
        ...vehcileData,
      });
    }
  }, [createAscDataLayer, dealerName, newModels, pageType, locale, vehicles, vehicle]);
  return null;
};

export default PageAnalytics;
