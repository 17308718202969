"use client";

import { useContext } from "react";
import AnalytycsContext from "./context";

const useAnalytics = () => {
  const contextValue = useContext(AnalytycsContext);
  if (!contextValue) {
    throw new Error("useAnalytics hook is used without Context Provider");
  }

  return contextValue;
};

export default useAnalytics;
